
.conditions-container h1 {
	text-align: center;
	margin-bottom: 20px;
	color: #333;
	word-break: break-word;
}

.conditions-container h2 {
	color: #555;
	margin: 20px;
	text-align: center;
	word-break: break-word;
}

.conditions-container p {
	margin-bottom: 10px;
	text-align: justify;
	color: #666;
	word-break: break-word;
}

.conditions-container a {
	color: var(--color-link) !important;
	text-decoration: none;
	cursor: pointer;
	/* 	Annuler le uppercase */
	text-transform: none;
	/* 	Annuler le letter-spacing */
	letter-spacing: normal;
}

.conditions-container a:hover {
	color: var(--color-link-hover) !important;
}
