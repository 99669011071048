/* Polices */
@font-face {
	font-family: 'Century Gothic';
	src: url('./centurygothic.ttf') format('truetype');
}

@font-face {
	font-family: 'Metric Medium';
	src: url('./metricmedium.ttf') format('truetype');
}

/* Appliquer les polices */
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Century Gothic', sans-serif;
	letter-spacing: .2em;
	text-transform: uppercase;
}

p,
span,
div,
li,
a {
	font-family: 'Metric Medium', sans-serif;
}

/* Styles par défaut (ordinateurs) */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
li,
a {
	word-break: break-word;
}

h1 {
	font-size: 32px;
	margin: 40px 0px;
	text-align: center;
	word-break: break-word;
}

h2 {
	font-size: 28px;
	margin-top: 20px;
	text-align: center;
	word-break: break-word;
}

h3 {
	font-size: 26px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 23px;
}

p {
	font-size: 16px;
}

a {
	font-size: 16px;
}

/* Pour tablettes (768px - 1024px) */
@media (max-width: 1024px) {
	h1 {
		font-size: 28px;
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 22px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 19px;
	}

	p {
		font-size: 15px;
	}

	a {
		font-size: 15px;
	}
}

/* Pour smartphones (moins de 768px) */
@media (max-width: 768px) {
	h1 {
		font-size: 26px;
	}

	h2 {
		font-size: 22px;
	}

	h3 {
		font-size: 20px;
	}

	h4 {
		font-size: 18px;
	}

	h5 {
		font-size: 17px;
	}

	p {
		font-size: 14px;
	}

	a {
		font-size: 14px;
	}
}