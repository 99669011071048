/* CategoryList.css */

.product-card {
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
	transform: translateY(-10px);
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-image-container {
	width: 100%;
	padding-bottom: 100%;
	/* Maintient un format carré */
	background-size: cover;
	background-position: center;
	background-color: #f5f5f5;
	border-radius: 8px;
	transition: transform 0.3s ease;
	overflow: hidden;
}

.product-card:hover .product-image-container {
	transform: scale(1.05);
}

.product-title {
	margin-top: 10px;
	font-size: 1.2em;
	color: #333;
	text-align: center;
}

/* Styles responsives pour garantir un format carré sur différents écrans */
@media (max-width: 768px) {
	.product-title {
		font-size: 1.1em;
	}
}

@media (max-width: 576px) {
	.product-image-container {
		max-width: 100%;
		max-height: auto;
	}
}

@media (max-width: 500px) {
	.product-image-container {
		max-width: 100%;
		max-height: auto;
	}
}