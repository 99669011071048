.product-card {
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
	transform: translateY(-10px);
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-image-container {
	overflow: hidden;
}

.product-image {
	transition: transform 0.3s ease;
}

.product-card:hover .product-image {
	transform: scale(1.1);
}