.privacy-policy-container {
	line-height: 1.6;
}

.privacy-policy-container h2 {
	margin: 35px 0px;
	color: #555;
}

.privacy-policy-container p,
.privacy-policy-container ul {
	margin-bottom: 10px;
	text-align: justify;
}

.privacy-policy-container a {
	color: var(--color-link) !important;
	text-decoration: none;
	cursor: pointer;
	/* 	Annuler le uppercase */
	text-transform: none;
	/* 	Annuler le letter-spacing */
	letter-spacing: normal;
}

.privacy-policy-container a:hover {
	color: var(--color-link-hover) !important;
}

.privacy-policy-container ul li {
	margin-bottom: 5px;
}