.favori-card {
	cursor: pointer;
	border: none;
	overflow: hidden;
	/* Pour que l'image reste dans les limites */
	transition: transform 0.2s;
}

.favori-card:hover {
	transform: scale(1.05);
}

.favori-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	/* Cette propriété ajuste l'image pour qu'elle remplisse le conteneur sans déformer */
	aspect-ratio: 1;
	/* Maintenir un ratio carré */
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	transition: transform 0.2s;
}

.favori-card-body {
	text-align: center;
	padding: 10px 0;
}

@media (max-width: 576px) {
	.favori-image {
		aspect-ratio: 1;
	}
}