/* ProductDetail.css */

/* Product Card Hover Effects */
.product-card {
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
	transform: translateY(-10px);
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Conteneur pour l'image principale */
.product-image-container {
	width: 100%;
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-color: #f5f5f5;
	border-radius: 8px;
	transition: transform 0.3s ease;
	overflow: hidden;
}

.product-card:hover .product-image-container {
	transform: scale(1.05);
}

/* Titre du produit */
.product-title {
	margin-top: 10px;
	font-size: 1.2em;
	color: #333;
	text-align: center;
}

/* Liste de miniatures */
.thumbnail-item {
	padding: 8px 5px !important;
}

.thumbnail-item img {
	width: 60px;
	height: 60px;
	object-fit: cover;
	border-radius: 5px;
	transition: border 0.3s ease;
	cursor: pointer;
	border: 1px solid #ddd;
}

.thumbnail-item img.selected-thumbnail {
	border: 2px solid #000;
}

.thumbnail-item img:hover {
	border: 2px solid #888;
}

/* Image principale du produit */
.selected-product-image {
	border-radius: 10px;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

/* Conteneur pour chaque image dans le carrousel sur mobile */
.carousel-image-container-mobile {
	width: 100%;
	padding-bottom: 100%;
	position: relative;
	overflow: hidden;
	border-radius: 10px;
}

.carousel-image-container-mobile img.carousel-image-mobile {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 8px;
}

/* Styles responsives pour les tailles d’écran */
@media (max-width: 768px) {
	.product-title {
		font-size: 1.1em;
	}
}

@media (max-width: 576px) {
	.product-image-container {
		max-width: 100%;
		max-height: auto;
	}
}

/* Responsiveness pour les images dans le carrousel sur mobile */
@media (max-width: 576px) {
	.carousel-image-container-mobile {
		max-width: 100%;
		max-height: auto;
	}
}

/* ProductDetail.css */

/* Conteneur principal de la galerie */
.gallery {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	justify-content: center;
}

/* Chaque item de la galerie */
.gallery-item {
	flex: 1 1 calc(50% - 16px);
	/* Deux items par ligne */
	max-width: calc(50% - 16px);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	overflow: hidden;
	transition: transform 0.3s ease;
	height: 100%;
}

/* Style de l'image pour occuper l'item */
.gallery-image {
	width: 100%;
	height: auto;
	object-fit: cover;
}

/* Responsive : Une image par ligne pour les petits écrans */
@media (max-width: 768px) {
	.gallery-item {
		flex: 1 1 100%;
		max-width: 100%;
	}
}