#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

input,
select,
textarea {
	border-radius: 20px !important;
}

input:hover,
select:hover,
textarea:hover {
	border: 1px solid #8c8cf2 !important;
}

main {
	flex: 1;
}

html,
body {
	min-height: 100%;
	margin: 0;
}

a{
	text-decoration: none !important;
}

/* Style général du bouton radio */
.form-check-input {
	background-color: #ffffff !important;
	/* Fond blanc par défaut */
	width: 1.5em;
	/* Taille du bouton radio */
	height: 1.5em;
	box-shadow: none !important;
	/* Pas d'ombre autour du bouton */
	transition: background-color 0.3s ease, border-color 0.3s ease;
	/* Transition douce */
	position: relative;
	/* Position relative pour centrer le background */
}

/* Lorsque l'input est sélectionné */
.form-check-input:checked {
	border: 2px solid #000000 !important;
	/* Bordure noire */
	background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23000%27/%3e%3c/svg%3e');
	background-size: 130% 130%;
	/* Ajuste la taille du background-image */
	background-position: center;
	/* Centre l'image dans l'input */
	background-repeat: no-repeat;
	/* Empêche la répétition de l'image */
}


:root {
	--color-title: #333356;
	--color-title-hover: #747474;

	--color-link: #8c8cf2;
	--color-link-hover: #8f8e8e;

	--font-family-title: 'Century Gothic', sans-serif;
	--font-family-text: 'Metric Medium', sans-serif;
}

/* Pour pouvoir se servir des couleurs directement en classe */
.color-title {
	color: var(--color-title) !important;
}

.color-title-hover:hover {
	color: var(--color-title-hover) !important;
}

.color-link {
	color: var(--color-link) !important;
}

.color-link-hover:hover {
	color: var(--color-link-hover) !important;
}

.font-family-title {
	font-family: var(--font-family-title) !important;
}

.font-family-text {
	font-family: var(--font-family-text) !important;
}



body {
	margin: 0;
	font-family: "CenturyGothic", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h2 {
	color: #000 !important;
}

/* Barre latérale */
.sidebar {
	position: fixed;
	top: 0;
	left: -320px;
	width: 320px;
	height: 100%;
	background-color: #f8f9fa;
	transition: left 0.3s ease-in-out;
	z-index: 1050;
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	font-family: "CenturyGothic", sans-serif;
}

.sidebar.open {
	left: 0;
}

.sidebar p,
.sidebar a {
	letter-spacing: .2em;
	text-transform: uppercase;
	cursor: pointer;
}

.close-btn-container {
	display: flex;
	justify-content: flex-start;
	padding: 10px 20px;
}

.close-btn {
	font-size: 1.2rem;
	background-color: transparent !important;
	color: #333 !important;
	border: none !important;
	cursor: pointer;
}

/* Liens dans la barre latérale */

.sidebar-menu-first {
	color: var(--color-title) !important;
	font-size: 16px !important;
}

.sidebar-menu-first:hover {
	color: var(--color-title-hover) !important;
	font-size: 18px !important;
}

.sidebar-menu-secondary {
	color: #939393 !important;
	font-size: 15px !important;
}

.sidebar-menu-secondary:hover {
	color: #333 !important;
	font-size: 17px !important;
}

.hr-menu {
	border: 1px solid #afafaf;
	margin: 10px 0;
}

/* Overlay pour fermer le menu en cliquant à l'extérieur */
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1049;
}

/* classe utiles non disponible sur boostrap */

.min-vh-25 {
	min-height: 25vh;
}

.min-vh-50 {
	min-height: 50vh;
}

.min-vh-75 {
	min-height: 75vh;
}

.max-vh-25 {
	max-height: 25vh;
}

.max-vh-50 {
	max-height: 50vh;
}

.max-vh-75 {
	max-height: 75vh;
}

.min-vw-25 {
	min-width: 25vw;
}

.min-vw-50 {
	min-width: 50vw;
}

.min-vw-75 {
	min-width: 75vw;
}

.max-vw-25 {
	max-width: 25vw;
}

.max-vw-50 {
	max-width: 50vw;
}

.max-vw-75 {
	max-width: 75vw;
}

.max-w-90 {
	max-width: 90% !important;
}

@media screen and (max-width: 572px) {
	.d-xs-none {
		display: none !important;
	}
}

.bg-t {
	background-color: transparent !important;
}

.cursor-pointer {
	cursor: pointer;
}

.card-hover {
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-hover:hover {
	transform: translateY(-10px);
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.hover:hover {
	color: var(--color-link) !important;
}