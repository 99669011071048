.burger-toggle {
	border: none !important;
	/* Supprimer la bordure */
	background: none;
	/* Supprimer l'arrière-plan par défaut */
	padding: 0;
	/* Ajuster l'espacement si nécessaire */
}

.burger-toggle:focus {
	box-shadow: none !important;
	/* Supprimer l'effet de focus (si présent) */
}

#navbar-logo {
	width: 50px;
	/* Largeur du logo de la navbar */
}

/* Animation pour les catégories */
.categories-container {
	max-height: 0;
	/* Hauteur maximale initiale */
	overflow: hidden;
	/* Masquer le contenu débordant */
	transition: max-height 0.3s ease-out;
	/* Transition pour l'animation de la hauteur */
}

.categories-container.open {
	max-height: 500px;
	/* Hauteur maximale lorsque ouvert */
	transition: max-height 0.5s ease-in;
	/* Transition pour l'animation de la hauteur */
}

/* Animation pour la rotation de l'icône */
.icon-rotate {
	display: inline-flex;
	/* Affichage en ligne avec flexbox */
	align-items: center;
	/* Aligner les éléments au centre */
	justify-content: center;
	/* Justifier les éléments au centre */
	width: 24px;
	/* Assurez-vous que la taille est constante */
	height: 24px;
	/* Assurez-vous que la taille est constante */
	transition: transform 0.3s ease-in-out;
	/* Transition pour l'animation de la rotation */
}

.icon-rotate.open {
	transform: rotate(180deg);
	/* Faire pivoter l'icône */
}

.navbar-home {
	background-color: transparent !important;
	/* Transparent sur la page d'accueil */
	position: absolute !important;
	/* Position absolue */
	top: 0;
	/* En haut de la page */
	left: 0;
	/* À gauche de la page */
	width: 100%;
	/* Largeur de 100% */
	z-index: 10;
	/* Pour s'assurer qu'elle est au-dessus du contenu */
}

.navbar {
	background-color: white;
	/* Les autres pages ont une navbar blanche */
}

.burger-toggle span:hover {
	/* Couleur de survol des barres du burger sur la page d'accueil */
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(140, 140, 242, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.burger-home span {
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
	/* Couleur des barres du burger sur la page d'accueil */
}

.navbar-home .custom-dropdown-toggle,.navbar-home .nav-link {
	color: white;
	/* Couleur blanche pour les liens et les toggles */
}

@media screen and (max-width: 572px) {
	.navbar svg {
		width: 20px !important;
		/* Largeur des icônes dans la navbar */
		height: 20px !important;
		/* Hauteur des icônes dans la navbar */
	}
}

.navbar svg {
	width: 28px;
	/* Largeur des icônes dans la navbar */
	height: 28px;
	/* Hauteur des icônes dans la navbar */
}
